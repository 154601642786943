@import '~antd/dist/antd.less';

.ant-layout-content {
  background: #ffffff !important;
}

.main-menu {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.header-admin.ant-layout-header {
  background: #ffffff;
  height: 9%;
}


.ant-divider-inner-text {
  border-radius: 2em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04), 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255) !important;
}

.layout-admin {
  position: relative;
  margin-top: 10px;
}

.layout-admin.ant-layout {
  background: #ffffff;
}

.content-wrapper {
  padding: 0 50px 10px 50px;
 
}

.container {
  display: flex;
  justify-content: center;
  
}

.child {
  position: absolute;
  top: 50%;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.pricing-config-table .ant-table .ant-table-content .ant-table-thead th.ant-table-row-expand-icon-cell:after {
  content:'Markup Configs'; 
  visibility: visible;
  background-color: #fafafa;
  text-align: center !important;
  display: block;
}
.sub-content-wrapper{
  margin: 0 auto;
  width: 80%;
}

.modify-row{
  color: #0d864e;
}
@primary-color: #fc4000;